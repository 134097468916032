<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <div class="secondrow">
        <div class="input">
          <!-- 输入框，尾部带按钮 -->
          会员账号：
          <el-input
            placeholder="请输入会员账号"
            v-model="queryInfo.mobile"
            clearable
            @clear="getDetailSys"
            @change="searchDetailSys"
          >
            <el-button
              slot="append"
              @click="searchDetailSys"
            >搜索</el-button>
          </el-input>
        </div>
        <!-- 状态筛选 -->
        <div class="status">
          <!-- 筛选框 -->
          消费类型：
          <el-select
            v-model="queryInfo.consumerType"
            placeholder="全部"
            clearable
            @clear="getDetailSys"
            @change="searchDetailSys"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
         <div class="grant">
        <el-button
          type="primary"
          @click="sendIntegralBtn"
        >发放积分</el-button>
      </div>
      </div>
      <!-- 积分明细列表 -->
      <el-table
        :data="userList"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column
          prop="id"
          width="100"
          label="id"
        > </el-table-column>
        <el-table-column
          prop="createTime"
          label="时间"
        > </el-table-column>
        <el-table-column
          prop="memberAccount"
          label="会员账号"
        >
        </el-table-column>
        <el-table-column
          prop="memberName"
          label="会员昵称"
        > </el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单编号"
        > </el-table-column>
        <el-table-column
          prop="consumerType"
          label="消费类型"
        >
          <!-- <template slot-scope="scope">
            <div v-if="scope.row.consumerType === 0">
              <div>兑换优惠券(线上)</div>
            </div>
            <div v-else-if="scope.row.consumerType === 1">
              <div>活动赠送</div>
            </div>
            <div v-else-if="scope.row.consumerType === 2">
              <div>会员等级升级赠送</div>
            </div>
            <div v-else-if="scope.row.consumerType === 3">
              <div>线上积分流水</div>
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          prop="pointsChange"
          label="本次积分"
        >
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        @size-change="handleSizeChange"
        :current-page="queryInfo.pageNo"
        :page-size="queryInfo.limit"
        :page-sizes="[15, 30, 50, 100]"
        @current-change="handleCurrentChange"
        layout="total,sizes,prev,pager,next,jumper"
      >
      </el-pagination>
    </el-card>
     <!-- 发放积分 -->
    <el-dialog
      title="发放积分"
      :visible.sync="integralDialogVisible"
      width="800px"
      @close="clearSendIntegral"
    >
      <el-form
        ref="sendIntegralRef"
        :rules="sendIntegralRules"
        :model="userIntegralForm"
        label-width="100px"
      >
        <el-form-item
          label="手机号"
          prop="mobile"
        >
          <el-input
            v-model="userIntegralForm.mobile"
            placeholder="请输入用户手机号"
            class="select"
            clearable=""
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="发放积分"
          prop="points"
        >
          <el-input
            v-model="userIntegralForm.points"
            placeholder="请输入发放积分"
            class="select"
            clearable=""
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="integralDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="sendIntegral"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userList: [],
      queryInfo: {
        limit: 15,
        pageNo: 1,
        consumerType: '',
        marketId: '',
        mobile: ''
      },
      total: 0,
      statusList: [
        {
          value: 0,
          label: '兑换优惠券(线上)'
        },
        {
          value: 1,
          label: '活动赠送'
        },
        {
          value: 2,
          label: '会员等级升级赠送'
        },
        {
          value: 3,
          label: '线上积分流水'
        }
      ],
      userIntegralForm: {
        mobile: '',
        points: ''
      },
      integralDialogVisible: false,
      sendIntegralRules: {
        mobile: [{ required: true, message: '请输入用户手机号', trigger: 'blur' }],
        points: [{ required: true, message: '请输入发放积分', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.queryInfo.mobile = this.$route.query.mobile
    this.getDetailSys()
  },
  methods: {
    searchDetailSys () {
      this.queryInfo.pageNo = 1
      this.getDetailSys()
    },
    async getDetailSys () {
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('/pointsDetails/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.userList = res.data.data
      // console.log(this.userList, 111)
      this.total = res.data.total
    },
    // 监听 limit 改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getDetailSys()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getDetailSys()
    },
    sendIntegralBtn() {
      this.integralDialogVisible = true
    },
    async sendIntegral () {
      this.$refs.sendIntegralRef.validate(async valid => {
        if (!valid) return
        // 可以发起网络请求
        const { data: res } = await this.$http.post(
          '/pointsDetails/sendPoints',
          this.userIntegralForm
        )
        if (res.code === 0) {
          this.$message.success('发放成功')
          this.userIntegralForm.mobile = ''
          this.integralDialogVisible = false
          this.getDetailSys()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    clearSendIntegral () { }
  }
}
</script>

<style lang="less" scoped>
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
}
.el-input {
  width: 250px;
}
.status {
  position: relative;
  margin-left: 360px;
  margin-top: -40px;
  .el-select {
    width: 180px;
  }
}
.grant {
  position: relative;
  margin-left: 640px;
  top: -40px;
}
</style>
